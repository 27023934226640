


























































































































































import {Component, Ref, Vue} from "vue-property-decorator";
import api from "@/api";
import {
  ServiceObjectCreateDto,
  DataDictionaryDtoPagedResultDto,
  DataDictionaryDto,
  ServiceOrganizationDto,
} from "@/api/appService";
import {ElForm} from "element-ui/types/form";
import {CodeToText, regionData} from "element-china-area-data";
import AbSelect from "@/components/AbSelect/index.vue";
import {stringify} from "uuid";

Vue.use(regionData, CodeToText);
@Component({
  components: {
    AbSelect,
  },
})
export default class CreateServiceObject extends Vue {
  @Ref() readonly dataForm!: ElForm;
  dataId = 0;
  form: ServiceObjectCreateDto = {
    sex: undefined,
  };
  serviceOrganizationList: ServiceOrganizationDto[] = [];
  options: any = regionData;
  selectedOptions: string[] = ["", "", ""];
  physicalStateTypeList: DataDictionaryDto[] = [];
  serviceObjectTypeList: DataDictionaryDto[] = [];

  async created() {
    await this.fetchPhysicalStateDataDictionary();
    await this.fetchServiceObjectDateDictionary();
    await this.fetchServiceOrgList();

    if ((this as any).$route.params.id) {
      this.dataId = Number((this as any).$route.params.id);
      await api.serviceObject.get({id: this.dataId}).then((res) => {
        this.form = {...res};
        this.selectedOptions = [
          res!.provinceId + "",
          res!.cityId + "",
          res!.areaId + "",
        ];
      });
    }
    this.$nextTick(() => {
      this.dataForm.clearValidate();
    });
  }

  async fetchServiceOrgList() {
    await api.serviceOrganization
      .getAllServiceOrganizationList()
      .then((res) => {
        this.serviceOrganizationList = [...res!];
        debugger;
      });
  }

  async fetchPhysicalStateDataDictionary() {
    await api.dataDictionary
      .getDataDictionaryListByKey({
        key: "PhysicalStateType",
        maxResultCount: 1000,
      })
      .then((res: DataDictionaryDtoPagedResultDto) => {
        this.physicalStateTypeList = res!.items!;
      });
  }

  async fetchServiceObjectDateDictionary() {
    await api.dataDictionary
      .getDataDictionaryListByKey({
        key: "ServiceObjectType",
        maxResultCount: 1000,
      })
      .then((res: DataDictionaryDtoPagedResultDto) => {
        this.serviceObjectTypeList = res!.items!;
      });
  }

  async save() {
    (this.$refs.dataForm as any).validate(async (valid: boolean) => {
      if (valid) {
        if (this.$route.params.id) {
          await api.serviceObject
            .update({
              body: this.form,
            })
            .then((res) => {
              (this as any).$message.success("更新成功");
              (this as any).$router.back();
            });
        } else {
          await api.serviceObject
            .create({
              body: this.form,
            })
            .then((res) => {
              (this as any).$message.success("新建成功");
              (this as any).$router.back();
            });
        }
      } else {
        (this as any).$message.error("请检查表单！");
      }
    });
  }

  cancel() {
    (this as any).$router.back();
  }

  //   省市区
  handleChange(value: any, label: any) {
    this.form!.provinceId = value[0];
    this.form!.cityId = value[1];
    this.form!.areaId = value[2];
  }

  roleRule = {
    name: [
      {
        required: true,
        message: "姓名必填",
        trigger: "blur",
      },
    ],
    sex: [
      {
        required: true,
        message: "性别必填",
        trigger: "blur",
      },
    ],
    serviceObjectTypeId: [
      {
        required: true,
        message: "服务对象类型必填",
        trigger: "blur",
      },
    ],
    areaId: [
      {
        required: true,
        message: "地址必填",
        trigger: "blur",
      },
    ],
  };
}
